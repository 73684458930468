import ProjectGallery from "../../components/ui/projectGallery";
import OtherProjects from "../../components/ui/otherProjects";
import linkIcon from "../../assets/images/icons/link2.svg"
import Logo from "../../components/ui/logo";
import github from "../../assets/images/icons/githubIcon.png"
import behance from "../../assets/images/icons/behanceIcon.png"
import linkedIn from "../../assets/images/icons/linkedInIcon.png"

import instagram from "../../assets/images/icons/instagramIcon.png"
import { Link } from "react-router-dom";
function Project() {
    return (
      <div className='pt-[13vh] pb-[5vh] w-[100vw] overflow-clip bg-black'>
        <section className="project relative">
          <div className="bg-black px-[5vw] min-md:px-[10.5vw] text-white font-semibold pb-[60px]"> 
            <div className="text-5xl lg:text-[50px] sm:text-[45px] xs:text-[35px]">
              Projects
            </div>
            <div className="flex gap-10 text-xl mt-8 md:mt-4 sm:mt-2 md:text-xs font-bold min-sm:gap-3 sm:flex-col sm:gap-0">
              <div className=""><span className="text-7xl lg:text-6xl sm:text-[60px]">600</span>hours of project work</div>
              <div className=""><span className="text-7xl lg:text-6xl sm:text-[60px]">30+</span>projects</div>
            </div>
            <div className="text-[40px] mt-2 lg:text-[40px] sm:text-[30px] xs:text-[25px]">
              I combine creativity and practicality to make <br className="lg:hidden"/>
              my work unique and effective.
            </div>
          </div>
          <ProjectGallery/>     
          <OtherProjects/> 
          
          <hr className="absolute bottom-0 w-[100%]"/>
        </section>
        <section className="footer mt-[10vh] mx-[5vw] md:mt-[5vh] text-white">
        <div className="font-semibold text-5xl min-md:leading-[59px] text-right md:text-3xl ">
          <div>Have an idea? Let's</div>
          <div>build and scale it</div>
        </div>
        <div className="text-right text-base mt-[30px] md:text-xs">
          <div>Feel free to reach out if you want to collaborate with</div>
          <div>us, or simply have a chat.</div>
        </div>
        <div className="flex justify-end items-center text-base mt-[30px] md:mt-[15px]">
          <Link to="/contact"><div><img src={linkIcon} alt="" className="md:h-[70px]"/></div></Link>
          <div className="md:text-xs">Contact me!</div>
        </div>
        <div className="flex items-end justify-between">
          <div>
            <Logo/>
            <div className="mt-[10px] md:text-[10px]">© Henry Onyejelem 2024.All rights reserved</div>
          </div>
          <div>
          <div className="flex gap-4 sm:gap-2">
          <div className="flex gap-4 sm:gap-2">
              <a href="https://github.com/henryonyejelem" target="_blank" rel="noopener noreferrer"><img src = {github} alt="" className="h-[40px] sm:h-[25px]"/></a>
              <a href="https://www.behance.net/henryonyejelem" target="_blank" rel="noopener noreferrer"><img src = {behance} alt="" className="h-[40px] sm:h-[25px]"/></a>
              <a href="https://www.linkedin.com/in/henryonyejelem/" target="_blank" rel="noopener noreferrer"><img src = {linkedIn} alt="" className="h-[40px] sm:h-[25px]"/></a>
              <a href="https://www.instagram.com/henryonyejelem/" target="_blank" rel="noopener noreferrer"><img src = {instagram} alt="" className="h-[40px] sm:h-[25px]"/></a>
            </div>
          </div>
          </div>
        </div>
      </section> 
        
      </div>
    )
  }
  
  export default Project;