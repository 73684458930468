const skills =[
    "c++",
    "python",
    "php",
    "ruby",
    "vue",
    "typescript",
    "tailwind",
    "photoshop",
    "opengl",
    "jenkins",
    "webpack",
    "MySQL",
    "git",
    "jest",
    "fullstack development",
    "frontend development",
    "user experience",
    "product design",
    "brand design"
]

export default skills