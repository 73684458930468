import github from "../../assets/images/icons/githubIcon.png"
import instagram from "../../assets/images/icons/instagramIcon.png"
import behance from "../../assets/images/icons/behanceIcon.png"
import linkedIn from "../../assets/images/icons/linkedInIcon.png"
import linkIcon from "../../assets/images/icons/link1.svg"
import Logo from "../../components/ui/logo"

import { useState } from "react"

function Contact() {
    const [result, setResult] = useState("");

    const onSubmit = async (event) => {
      event.preventDefault();
      setResult("Sending....");
      const formData = new FormData(event.target);

      formData.append("access_key", "b103dfb7-034a-4f20-9136-6c81e0e6963c");

      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData
      });

      const data = await response.json();

      if (data.success) {
        setResult("Form Submitted Successfully");
        event.target.reset();
      } else {
        console.log("Error", data);
        setResult(data.message);
      }
    };

    console.log(result)

    return (
      <div className=''>
        <section className="h-[100vh] flex items-center mx-[11vw] justify-center">
          <div>
            <div className="text-5xl font-semibold md:text-4xl sm:text-3xl">Contact Me</div>
            <div className="text-3xl font-semibold md:text-2xl sm:text-xl">Need to get in touch with me? Either fill out the form with your inquiry or send me an <span className="underline text-[#1592E6] cursor-pointer" onClick={(e) => {window.location.href ='mailto:onyejelemco@gmail.com';}}>email</span> </div>

            <form onSubmit={onSubmit}>
              <div className="flex text-2xl mt-10 gap-8 sm:flex-col">
                <input type="hidden" name="access_key" value="b103dfb7-034a-4f20-9136-6c81e0e6963c"/>
                <input className="flex-grow pb-4 border-b-[1px] outline-none border-black" name="email" required placeholder="Email"/>
                <input className="flex-grow pb-4 border-b-[1px] outline-none border-black" name="inquiry" required placeholder="Inquiry"/>
              </div>                       

              <div className="flex items-center justify-between mt-5">
                <div className="flex gap-4 sm:gap-2">
                  <img src = {github} alt="" className="h-[35px] sm:h-[25px]"/>
                  <img src = {behance} alt="" className="h-[35px] sm:h-[25px]"/>
                  <img src = {linkedIn} alt="" className="h-[35px] sm:h-[25px]"/>
                  <img src = {instagram} alt="" className="h-[35px] sm:h-[25px]"/>
                </div>
                <div className="flex text-xl items-center gap-2">
                  <div>Submit</div>
                  <button type="submit"><img src = {linkIcon} alt="" className="h-[40px] sm:h-[25px]"/></button>
                </div>
              </div>
            </form> 
          </div>

        </section>
        <section className="footer mx-[3vw] mt-[3vh] md:mt-[2vh]">          
          <div className="flex items-end justify-between">
            <div>
              <Logo/>
              <div className="mt-[10px] md:text-[10px]">© Henry Onyejelem 2024.All rights reserved</div>
            </div>            
          </div>
        </section>  
      </div>
    )
  }
  
  export default Contact;