import rosario from "../../assets/images/desktop/project/rosario.jpg"
import oldsoul from "../../assets/images/desktop/project/oldsoul.jpg"
import diverd from "../../assets/images/desktop/project/diverd.jpg"
import lanternlight from "../../assets/images/desktop/project/lanternlight.jpg"
import GalleryImage from "./galleryImage"
import {motion} from "framer-motion"
import useMouse from "@react-hook/mouse-position";
import { useRef, useState } from "react"
import { Link } from "react-router-dom"

function ProjectGallery(props) { 
  const [cursorVariant, setCursorVariant] = useState("default");
  const [isShown, setIsShown] = useState(false)

  const ref = useRef(null);
  const mouse = useMouse(ref);

  let mouseXPosition = 0;
  let mouseYPosition = 0;

  if (mouse.x !== null) {
    mouseXPosition = mouse.clientX;
  }

  if (mouse.y !== null) {
    mouseYPosition = mouse.clientY;
  }

  const variants = {
    default: {
      opacity: 0,
      height: 10,
      width: 10,
      fontSize: "16px",
      backgroundColor: "#1e91d6",
      x: mouseXPosition,
      y: mouseYPosition,
      transition: {
        type: "spring",
        mass: 0.6
      }
    },
    project: {
      fontSize: "18px",
      x: mouseXPosition - 45,
      y: mouseYPosition - 45
    }
  };

  const spring = {
    type: "spring",
    stiffness: 500,
    damping: 28
  };

  function projectEnter(event) {
    setIsShown(true)
    setCursorVariant("project");
  }

  function projectLeave(event) {
    setIsShown(false)
    setCursorVariant("default");
  }


  return (
    <div className="flex flex-wrap lg:flex-col bg-black relative cursor-none" ref={ref}     
      onMouseEnter={projectEnter}
      onMouseLeave={projectLeave}
    >
      { 
        isShown &&
        <>
          <motion.div
            variants={variants}
            className="fixed z-[31] opacity-0 top-0 left-0 leading-6 rounded-full bg-white text-center animate-circle font-semibold md:hidden"
            animate={cursorVariant}
            transition={spring}
          >
            <div>See</div>
            <div>Project</div>            
          </motion.div>
        </>
      }

        <Link to="/projects/old-soul"><GalleryImage src={oldsoul} title="Where Style Meets Expression" year={2024} tags={["Web Design", "Fullstack Development", "UI/UX"]}/></Link>
        <Link to="/projects/diverd"><GalleryImage src={diverd} title="Discover Relieve Enjoy" year={2023} tags={["UI/UX"]}/></Link>
        <Link to="/projects/little-boy-from-rosario"><GalleryImage src={rosario} title="Inspired Historic Victory" year={2023} tags={["Web Design", "Frontend Development", "UI/UX"]}/></Link>
        <Link to="/projects/lantern-light-kitchen"><GalleryImage src={lanternlight} title="Chinese Culinary Heritage" year={2023} tags={["Web Design", "Fullstack Development", "UI/UX"]}/> </Link>  
    </div>
  )
}

export default ProjectGallery