import SlideDown from "./slideDown";

function TextSlide(props) {
    const word = props.word.split("");
  return (
    <div className="flex gap-[0.02rem]">{word.map((letter, i) => (
        <SlideDown delay={props.delay + (0.1*i)} duration={props.duration}>{letter}</SlideDown>
    ))}</div>
  )
}

export default TextSlide