const coursework =[
    "data structures and algorithms",
    "software engineering",
    "software design",
    "database management",
    "graphic design",
    "introduction to react",
    "web design and development"
]

export default coursework