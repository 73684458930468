import coding from "../../assets/images/icons/codingIcon.svg"
import codingInvert from "../../assets/images/icons/codingInvert.svg"
function Logo(props) {
  return (
    <div className="flex font-bold items-center text-3xl gap-1">
        <img src={props.invert ? codingInvert : coding} alt="" className="h-[1em]" style={{color:props.invert ? "#FFFFFF" : "#000000"}}/>
        <div style={{color:props.invert ? "#FFFFFF" : "#000000"}}>HO</div>
    </div>
  )
}

export default Logo