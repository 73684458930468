import { useState, useEffect } from 'react'
import Home from "./pages/home/index.js";
import Project from "./pages/projects/index.js"
import Contact from "./pages/contact/index.js"
import LoadingPage from "./components/ui/loadingPage.js";
import Nav from './components/ui/nav.js';
import NavInvert from './components/ui/navInvert.js';
import OldSoul from './pages/projects/oldSoul.js';
import LanternLightKitchen from './pages/projects/lanternLightKitchen.js';
import Rosario from './pages/projects/rosario.js';
import Diverd from './pages/projects/diverd.js';
import { Route, Routes, useLocation } from "react-router-dom"

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const [isLoading, setIsLoading] = useState(true); // Initially set isLoading to true

  useEffect(() => {
    // Simulate loading for 4 seconds on initial load
    setTimeout(() => {
      setIsLoading(false); // After 4 seconds, set isLoading to false
    }, 4000);
  }, []);

  return (
    <>  
      {isLoading ? <LoadingPage /> :  
      <> 
        <ScrollToTop/>
        <NavbarWrapper/>           
        <Routes>          
          <Route path="/" element={<Home/>}/>
          <Route path="/projects" element={<Project/>}/>
          <Route path="/projects/old-soul" element={<OldSoul/>}/>
          <Route path="/projects/lantern-light-kitchen" element={<LanternLightKitchen/>}/>
          <Route path="/projects/little-boy-from-rosario" element={<Rosario/>}/>
          <Route path="/projects/diverd" element={<Diverd/>}/>
          <Route path="/contact" element={<Contact/>}/>
        </Routes>
      </>
      }
    </>
  );
}

function NavbarWrapper() {
  let location = useLocation();

  // Define an array of paths where you want to hide the navbar
  const invertNavbarPaths = ['/projects'];

  // Check if the current path should hide the navbar
  const otherNavbar = invertNavbarPaths.includes(location.pathname);

  // Render the navbar only if the current path doesn't match the paths where the navbar should be hidden
  if (!otherNavbar) {
    return <Nav />;
  }
  return <NavInvert/>;
}


export default App;
