import Logo from "./logo"
import Clock from "./clock"
import { Turn as Hamburger } from 'hamburger-react'
import {useState, useEffect} from 'react'
import { Link, useLocation } from "react-router-dom"

function NavInvert() {   
    const [isOpen, setOpen] = useState(false) 
    let location = useLocation();
    const includesProject = ['/projects'];
    const includesContact = ['/contact'];

    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true);
    let currentPath = "home";

    if(includesProject.includes(location.pathname)){
        currentPath = "projects";
    }
    if(includesContact.includes(location.pathname)){
        currentPath = "contact";
    }

    useEffect(() => {
        const handleScroll = () => {
        const currentScrollPos = window.scrollY;

        setVisible(
            (prevScrollPos > currentScrollPos) ||
            currentScrollPos < 10
        );

        if(prevScrollPos < currentScrollPos){
            setOpen(false)
        }


        setPrevScrollPos(currentScrollPos);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos, visible]);

    const style1 = isOpen ? "fixed z-[60] w-[100vw] h-[100vh] bg-black top-0 opacity-50" : ""
    const style2 = isOpen ? "fixed z-[70] top-0 right-[3vw] my-3 w-[40vw] md:w-[94vw] bg-white rounded-[32px] py-10 px-10 origin-top-right scale-100 opacity-100" : "fixed z-[70] top-0 right-[3vw] my-3 w-[40vw] bg-white rounded-[32px] py-10 px-10 origin-top-right scale-75 opacity-0"


  return (
    <div className={`navbar ${visible ? '' : '-translate-y-full ease-out'} transition delay-300 duration-1000 fixed w-[100vw] z-[100] top-0 bg-black bg-opacity-25 backdrop-blur-lg`}>
        {            
            <>
                <div className={style1}>
                </div>
                <div className={style2} style={{transition: "transform 0.4s, opacity 0.4s"}}>
                    <div className="flex justify-between items-center">
                        <div><Clock/></div>
                    </div>
                    <div className="text-4xl"> 
                        <Link to ="/"><div className="min-sm:hidden">Home</div></Link>
                        <Link to ="/projects"><div className="min-sm:hidden">Projects</div></Link>
                        <Link to ="/contact"><div className="min-sm:hidden">Contact</div></Link>
                        
                        <Link to ="/projects/old-soul">
                        <div>
                            Latest Project
                        </div></Link>
                    </div>
                </div>
            </>
        }      
        
        <div className="flex items-center justify-between py-3 text-base mx-[3vw]">
            <div className="flex items-center">
                <Link to="/"><Logo invert={true}/></Link>
                <div className="flex gap-3 ml-[2vw] text-[#aaaaaa] md:hidden">
                    <Link to={'/'}>{currentPath === "home" ? <div className="font-semibold text-white">Home</div> : <div>Home</div>}</Link>
                    <Link to={'/projects'}>{currentPath === "projects" ? <div className="font-semibold text-white">Projects</div> : <div>Projects</div>}</Link>
                    <Link to={'/contact'}>{currentPath === "contact" ? <div className="font-semibold text-white">Contact</div> : <div>Contact</div>}</Link>
                </div>
            </div>
            <div className="flex gap-[69px] items-center sm:gap-[5px]">
                <div className="sm:hidden">
                    <Clock invert={true}/>
                </div>
                                
                <div className="relative z-[100]" style={{marginTop:isOpen ? "25px" : "0px", marginRight:isOpen ? "25px" : "0px", transition: "marginTop 0.2s, marginBottom 0.2s"}} >                
                    <Hamburger size={18} color={isOpen ? "#000000" : "#FFFFFF"} toggle={setOpen} toggled={isOpen}/>
                    <div className="border-[1.5px] border-[#707070] rounded-full h-[40px] w-[40px] absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] -z-40"></div>    
                </div> 
                
            </div>

        </div>
    </div>
  )
}

export default NavInvert