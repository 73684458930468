import OtherProjectItem from "./otherProjectItem"
import otherProjects from "../../data/otherProjects"

function OtherProjects() {
  return (
    <div className="text-white mt-[40px] mx-10 sm:mx-[3vw]">
        <div className="md:text-[14px]">More Relevant Projects</div>
        <hr className="mt-10 md:mt-5"/>
        <div className="flex justify-center flex-wrap md:flex-col md:items-center">
            {otherProjects.map((project)=>(
              <OtherProjectItem title={project.title}/>
            ))}
        </div>
        
    </div>
  )
}

export default OtherProjects