import linkIcon from "../../assets/images/icons/link2.svg"
function OtherProjectItem(props) {
  return (
    <div>
        <div className="w-[35vw] md:w-[80vw] border border-y-[0px] aspect-square">
            <div className="flex items-start justify-between p-5">
                <div className="text-2xl md:text-xl sm:text-base w-[80%] min-2xl:text-3xl">{props.title}</div>
                <img src={linkIcon} alt="" className="h-[50px]"/>
            </div>
        </div>
        <hr/>
    </div>
  )
}

export default OtherProjectItem