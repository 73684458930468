import location from "../../assets/images/icons/locationIcon.svg"
import locationInvert from "../../assets/images/icons/locationIconInvert.svg"
import { useState, useEffect } from 'react';

function Clock(props) {
  const [dallasTime, setDallasTime] = useState(getDallasTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setDallasTime(getDallasTime());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  function getDallasTime() {
    const options = { 
        timeZone: 'America/Chicago', 
        hour12: true, // Use 12-hour format
        hour: 'numeric',
        minute: '2-digit'
    };
    return new Date().toLocaleString('en-US', options);
  }

  

  return (
    <div className="text-[#707070] font-semibold flex items-center gap-2">
        <img src={props.invert ? locationInvert : location} className="h-[1em]" alt=""/>
        <p style={{color:props.invert ? "#aaaaaa" : "#707070"}}>Houston, Texas <span style={{color:props.invert ? "#FFFFFF" : "#000000"}}>{dallasTime}</span></p>
    </div>
  )
}

export default Clock