const otherProjects =[
    {
        title: "Fuel Rate Software Application"
    },
    {
        title: "Digital Image Processing"
    },
    {
        title: "Operating System Page Replacement Algorithm"
    },
    {
        title: "Issue Tracker Web Application"
    },
    {
        title: "Personal Portfolio Website"
    },
    {
        title: "Chess Competition Tracker"
    },
    {
        title: "Advanced Towers of Hanoi"
    },
    {
        title: "Huffman Algorithm Implementation"
    },
    {
        title: "Dijkstar Algorithm Implementation"
    },
    {
        title: "JokesApp Web Application"
    },
    {
        title: "Tic-Tac-Toe Web Application"
    },
    {
        title: "Regular Expression Registration Form"
    },
]

export default otherProjects