import "./index.css"
import xd from "../../assets/images/icons/adobexdIcon.png"
import illustrator from "../../assets/images/icons/illustratorIcon.png"
import photoshop from "../../assets/images/icons/photoshopIcon.png"
import mockups from "../../assets/images/desktop/project/diverdMockup.jpg"
import Footer from "../../components/ui/footer"
import SlideUp from "../../components/ui/slideUp"

function Diverd() {
    const height = window.innerHeight;
  return (
    <div className="mb-[3vw] w-[100vw]">
        <section className="diverd-project flex" style={{height:height}}>
            <div className="flex self-end text-white p-[5vw] justify-between w-[100%] min-xs:items-center xs:flex-col xs:gap-3">
            <div className="min-2xl:text-[80px] text-[55px] font-semibold sm:text-[50px]"><SlideUp delay={0.1} duration={0.8}>Diverd</SlideUp></div>
                <div className="flex gap-5 text-xl min-2xl:text-3xl sm:text-sm  sm:gap-3">
                    <div><SlideUp delay={0.3} duration={0.6}>2023</SlideUp></div>
                    <div><SlideUp delay={0.45} duration={0.6}>Web Design</SlideUp></div>
                    <div><SlideUp delay={0.6} duration={0.6}>UI/UX</SlideUp></div>
                </div>
            </div>
        </section>
        <section className="information px-[5vw] pt-[5vw] text-xl">
            <div className="min-md:w-[650px] min-2xl:w-[1000px] min-2xl:text-[80px] min-2xl:leading-[95px] add-stroke text-[56px] leading-[77px] sm:text-[45px] sm:leading-[60px] xs:text-[40px] xs:leading-[50px]">
                DISCOVER<br/>RELIVE<br/>ENJOY.
            </div>
            <section className="description flex gap-[4rem] mt-[60px] md:flex-col">
                <div className="min-md:w-[50%]">
                The DVD Collection Website is an online platform designed for DVD enthusiasts to organize, browse, and manage their collections efficiently. It features user registration, authentication, and personalized profiles. 
                </div>
                <div className="min-md:w-[50%]">
                Social features allow users to connect with other collectors, share recommendations, and participate in community forums. The website targets DVD enthusiasts and movie buffs seeking a centralized platform for managing their collections and discovering new titles. 
                </div>
            </section>
            <section className="duration&links flex gap-[4rem] mt-[70px] md:flex-col md:gap-4">
                <div className="min-md:w-[50%]">
                    <div className="text-sm text-[#707070]">Duration</div>
                    <div className="text-[130px] font-bold">2<span className="text-xl">weeks</span></div>                    
                </div>
            </section>
            <section className="tools min-md:w-[45%] mt-[60px]">
                <div className="text-sm text-[#707070]">Tools</div>    
                <div className="flex flex-wrap mt-6 gap-9">
                    <img src={xd} alt="" className="h-[50px]"/>
                    <img src={illustrator} alt="" className="h-[50px]"/>
                    <img src={photoshop} alt="" className="h-[50px]"/>
                </div>            
            </section>
        </section>
        <section className="web-design pt-[12vh]">
            <div className="px-[5vw]">
                <div className=" text-[45px] add-stroke">Web Design</div>
                <div className="text-base flex gap-[150px] mt-10">
                    <div className="flex flex-col gap-6">
                        <div>
                            The DVD collection website features a user-friendly interface with a homepage displaying 
                            popular releases and navigation menus for easy access. Users can browse DVDs by genre, 
                            release date, and other criteria, with a search bar and advanced filtering options available. 
                            Each DVD listing includes cover images, titles, descriptions, and details. Detailed pages 
                            provide more information, including cast, crew, ratings, and reviews. The site offers user 
                            accounts for registration, login, and personalized features like wishlists and order tracking. 
                            Responsive design ensures compatibility across devices, while a streamlined checkout process and 
                            multiple payment options facilitate secure transactions. Social integration allows users to share 
                            content, and security measures protect sensitive data. The footer contains links to essential pages 
                            and copyright information.
                        </div>
                    </div>
                </div>                
            </div>
        </section>
        <section className="mockups pt-[12vh]">
            <img src={mockups} alt="" className="w-[100vw]"/>
        </section>
        
        <Footer/>
    </div>
  )
}

export default Diverd