import {motion, useScroll, useTransform} from "framer-motion"
import {useRef, useState} from 'react'
import SlideUp from "./slideUp"

function useParallax(value) {
    return useTransform(value, [0, 1], ["-30vh", "5vh"]);
} 

function GalleryImage(props) {
    const ref = useRef(null)
    const { scrollYProgress } = useScroll({
        target: ref,
        offset: ["start end", "end start"]
    })

    const y = useParallax(scrollYProgress)

    const [isShown, setIsShown] = useState(false);

    const width = window.innerWidth

  return (
    <div className="w-[50vw] lg:w-[100vw] sm:aspect-[1/1] lg:aspect-[1.5/1] 2xl:aspect-[1.15/1] min-2xl:aspect-[1.9/1] overflow-hidden relative text-white" ref={ref}
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
    >
        <div className="h-[150%] w-[100%] absolute z-[50]"></div>
        {
            isShown &&
            <div className="md:hidden">
                <div className="absolute top-0 z-30 flex justify-between w-[50vw] px-10 py-5 lg:w-[100vw]">
                    <SlideUp duration={1} delay={0.3}><div className="flex gap-4">
                        {
                            props.tags.map((tag) => (
                                <div className="animate-slideDown">{tag}</div>
                            ))
                        }                        
                    </div></SlideUp>    
                    <SlideUp duration={1} delay={0.3}>{props.year}</SlideUp>       
                </div>
                <div className="absolute z-30 bottom-0 px-10 pb-10 text-4xl font-semibold"><SlideUp duration={1} delay={0}>{props.title}</SlideUp></div>
            </div>
        }

        <div className="min-md:hidden">
            <div className="absolute top-0 z-30 flex justify-between w-[100vw] px-10 py-5 sm:px-[3vw] sm:text-[14px]">
                <div className="flex gap-4">
                    {
                        props.tags.map((tag) => (
                            <div className="animate-slideDown">{tag}</div>
                        ))
                    }                        
                </div>   
                <div>{props.year}</div>  
            </div>
            <div className="absolute z-30 bottom-0 px-10 pb-10 text-4xl sm:text-[35px] sm:w-[90%] sm:leading-[50px] font-semibold sm:px-[3vw]">{props.title}</div>
        </div>
        
        {width > 650 ? <motion.img src = {props.src} alt="" style={{translateY : y, opacity : isShown ? 0.5 : 1}} className="w-[60vw] h-[100vh] object-cover md:h-[75vh] lg:h-[100vh] lg:w-[100vw]  transition-opacity ease-[cubic-bezier(0.65, 0, 0.35, 1)] duration-[1500ms]"/> : <img src={props.src} alt="" className="opacity-80 object-cover"/>}
    </div>
  )
}

export default GalleryImage