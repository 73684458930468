import Logo from "../../components/ui/logo.js"
import github from "../../assets/images/icons/githubIcon.png"
import instagram from "../../assets/images/icons/instagramIcon.png"
import behance from "../../assets/images/icons/behanceIcon.png"
import linkedIn from "../../assets/images/icons/linkedInIcon.png"
import linkIcon from "../../assets/images/icons/link1.svg"
import { Link } from "react-router-dom"
function Footer() {
  return (
    <section className="footer mt-[10vh] mx-[5vw] md:mt-[5vh]">
        <div className="font-semibold text-5xl min-md:leading-[59px] text-right md:text-3xl ">
            <div>Have an idea? Let's</div>
            <div>build and scale it</div>
        </div>
        <div className="text-right text-base mt-[30px] md:text-xs">
            <div>Feel free to reach out if you want to collaborate with</div>
            <div>us, or simply have a chat.</div>
        </div>
        <div className="flex justify-end items-center text-base mt-[30px] md:mt-[15px]">
            <Link to="/contact"><div><img src={linkIcon} alt="" className="md:h-[70px]"/></div></Link>
            <div className="md:text-xs">Contact me!</div>
        </div>
        <div className="flex items-end justify-between">
            <div>
            <Logo/>
            <div className="mt-[10px] md:text-[10px]">© Henry Onyejelem 2024.All rights reserved</div>
            </div>
            <div>
            <div className="flex gap-4 sm:gap-2">
              <a href="https://github.com/henryonyejelem" target="_blank" rel="noopener noreferrer"><img src = {github} alt="" className="h-[40px] sm:h-[25px]"/></a>
              <a href="https://www.behance.net/henryonyejelem" target="_blank" rel="noopener noreferrer"><img src = {behance} alt="" className="h-[40px] sm:h-[25px]"/></a>
              <a href="https://www.linkedin.com/in/henryonyejelem/" target="_blank" rel="noopener noreferrer"><img src = {linkedIn} alt="" className="h-[40px] sm:h-[25px]"/></a>
              <a href="https://www.instagram.com/henryonyejelem/" target="_blank" rel="noopener noreferrer"><img src = {instagram} alt="" className="h-[40px] sm:h-[25px]"/></a>
            </div>
            </div>
        </div>
    </section>
  )
}

export default Footer