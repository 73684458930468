import "./index.css"
import github from "../../assets/images/icons/github.svg"
import behance from "../../assets/images/icons/behance.svg"
import link from "../../assets/images/icons/link1.svg"
import xd from "../../assets/images/icons/adobexdIcon.png"
import illustrator from "../../assets/images/icons/illustratorIcon.png"
import react from "../../assets/images/icons/reactIcon.png"
import express from "../../assets/images/icons/expressIcon.png"
import mongo from "../../assets/images/icons/mongoDBIcon.png"
import framer from "../../assets/images/icons/framerIcon.png"
import photoshop from "../../assets/images/icons/photoshopIcon.png"
import node from "../../assets/images/icons/nodeIcon.png"
import mockups from "../../assets/images/desktop/project/lanternLightKitchenMockup.jpg"
import Footer from "../../components/ui/footer"
import SlideUp from "../../components/ui/slideUp"

function LanternLightKitchen() {
    const height = window.innerHeight;
  return (
    <div className="mb-[3vw] w-[100vw]">
        <section className="lantern-light-kitchen-project flex" style={{height:height}}>
            <div className="flex self-end text-white p-[5vw] justify-between w-[100%] min-xs:items-center xs:flex-col xs:gap-3">
                <div className="min-2xl:text-[80px] text-[55px] font-semibold sm:text-[50px] sm:hidden"><SlideUp delay={0.1} duration={0.8}>Lantern Light Kitchen</SlideUp></div>
                <div className="font-semibold text-[50px] min-sm:hidden">
                    <div><SlideUp delay={0.1} duration={0.8}>Lantern Light</SlideUp></div>
                    <div><SlideUp delay={0.2} duration={0.8}>Kitchen</SlideUp></div>
                </div>
                <div className="flex gap-5 text-xl min-2xl:text-3xl sm:text-sm  sm:gap-3">
                    <div><SlideUp delay={0.3} duration={0.6}>2023</SlideUp></div>
                    <div><SlideUp delay={0.45} duration={0.6}>Fullstack Development</SlideUp></div>
                    <div><SlideUp delay={0.6} duration={0.6}>UI/UX</SlideUp></div>
                </div>
            </div>
        </section>
        <section className="information px-[5vw] pt-[5vw] text-xl">
            <div className="min-md:w-[650px] min-2xl:w-[1000px] min-2xl:text-[80px] min-2xl:leading-[95px] add-stroke text-[56px] leading-[77px] sm:text-[45px] sm:leading-[60px] xs:text-[40px] xs:leading-[50px]">
                Unveil the essence of Chinese culinary heritage in every bite. 
                Welcome to a culinary journey illuminated by Lantern Light.
            </div>
            <section className="description flex gap-[4rem] mt-[60px] md:flex-col">
                <div className="min-md:w-[50%]">
                    This project aims to create an engaging and informative website for a fictional Chinese restaurant, Lantern Light Kitchen.
                    The primary goal of this project was to showcase the culinary expertise in the realm of Chinese cuisine. 
                </div>
                <div className="min-md:w-[50%]">
                    By presenting dishes, cooking techniques, and the cultural significance behind them, I aimed to convey the passion and 
                    dedication that goes into crafting each meal. Providing an interactive and user-friendly menu on the website.
                </div>
            </section>
            <section className="duration&links flex gap-[4rem] mt-[70px] md:flex-col md:gap-4">
                <div className="min-md:w-[50%]">
                    <div className="text-sm text-[#707070]">Duration</div>
                    <div className="text-[130px] font-bold">10<span className="text-xl">weeks</span></div>                    
                </div>
                <div className="min-md:w-[50%]">                    
                    <div className="text-sm text-[#707070]">Links</div>
                    <div className="mt-[50px] flex gap-3 sm:gap-4">
                        <div className="flex items-end gap-2">
                            <img src={github} className="h-[85px] sm:h-[55px]" alt=""/>
                            <div className="flex items-center">
                                <div>Github</div>
                                <a href="https://github.com/henryonyejelem/lantern-light-kitchen" target="_blank" rel="noopener noreferrer"><img src={link} alt="" className="h-[30px]"/></a>
                            </div>                            
                        </div>
                        <div className="flex items-end gap-2">
                            <img src={behance} className="h-[85px] sm:h-[55px]" alt=""/>
                            <div className="flex items-center">
                                <div>Behance</div>
                                <a href="https://www.behance.net/gallery/184154497/Lantern-Light-Kitchen" target="_blank" rel="noopener noreferrer"><img src={link} alt="" className="h-[30px]"/></a>
                            </div>                            
                        </div>                    
                    </div>  
                </div>
            </section>
            <section className="timeline mt-[60px]">
                <div className="text-sm text-[#707070]">Timeline</div>
                <div className="grid grid-cols-2 md:grid-cols-1 gap-[4rem] text-base mt-5">
                    <div className="">
                        <div className="font-semibold">1-2 week</div>
                        <div className="mt-3">
                            During the initial phase, you focused on planning and research, 
                            defining project objectives and scoping out requirements. 
                            Extensive research included examining analogous websites for
                            inspiration and formulating architecture and database structure.
                        </div>
                    </div>
                    <div className="">
                        <div className="font-semibold">3-4 week</div>
                        <div className="mt-3">
                            The subsequent phase involved design and wireframing. You crafted wireframes
                            and mockups for various pages, deciding layout, color schemes, typography, 
                            and visual aesthetics. Additionally, you sourced or created graphical elements to enhance visual appeal.
                        </div>
                    </div>
                    <div className="">
                        <div className="font-semibold">5-6 week</div>
                        <div className="mt-3">
                            Transitioning to frontend development, you established your MERN project and constructed frontend 
                            components using React.js. Ensuring responsiveness across devices and screen sizes was a priority.
                        </div>
                    </div>
                    <div className="">
                        <div className="font-semibold">7-8 week</div>
                        <div className="mt-3">
                            Backend development ensued, leveraging Node.js and Express.js to build the backend 
                            infrastructure, create RESTful APIs, and implement user authentication and CRUD operations.
                        </div>
                    </div>
                    <div className="">
                        <div className="font-semibold">9-10 week</div>
                        <div className="mt-3">
                            In the final phase, integration, testing, and preparatory stages for deployment were undertaken. 
                            I seamlessly fused frontend and backend components, rigorously tested for anomalies, and optimized performance. 
                        </div>
                    </div>
                    
                    
                    
                </div>

            </section>
            <section className="tools min-md:w-[45%] mt-[60px]">
                <div className="text-sm text-[#707070]">Tools</div>    
                <div className="flex flex-wrap mt-6 gap-9">
                    <img src={xd} alt="" className="h-[50px]"/>
                    <img src={illustrator} alt="" className="h-[50px]"/>
                    <img src={photoshop} alt="" className="h-[50px]"/>
                    <img src={react} alt="" className="h-[50px]"/>
                    <img src={framer} alt="" className="h-[50px]"/>
                    <img src={node} alt="" className="h-[50px]"/>
                    <img src={express} alt="" className="h-[50px]"/>
                    <img src={mongo} alt=""className="h-[50px]"/>
                </div>            
            </section>
        </section>
        <section className="web-design pt-[12vh]">
            <div className="px-[5vw]">
                <div className=" text-[45px] add-stroke">Web Design</div>
                <div className="text-base flex gap-[150px] mt-10">
                    <div className="flex flex-col gap-6">
                        <div>
                            The Chinese restaurant website design blends traditional elegance with modern simplicity. 
                            A clean and intuitive navigation bar guides visitors through sections like "Home," "Menu," 
                            "About Us," "Reservations," "Gallery," and "Contact." The homepage features captivating 
                            images of Chinese dishes and the restaurant's ambiance, accompanied by a brief introduction. 
                        </div>
                        <div>
                            The menu section showcases authentic Chinese delicacies with enticing descriptions and high-quality 
                            images. The "About Us" page shares the restaurant's history, culinary philosophy, and personal anecdotes. 
                            A user-friendly reservation system allows for easy table bookings, while the gallery section offers visual 
                            delights of the restaurant's decor and dishes. Contact information is easily accessible, and the design incorporates 
                            vibrant colors, subtle ornamental accents, and legible fonts to enhance the overall user experience.
                        </div>
                    </div>
                </div>                
            </div>
        </section>
        <section className="mockups pt-[12vh]">
            <img src={mockups} alt="" className="w-[100vw]"/>
        </section>
        <section className="web development pt-[12vh] px-[5vw]">
            <div className=" text-[45px] add-stroke">Web Development</div>
            <div className="text-base gap-[150px] mt-10">
                <div className="flex flex-col gap-6">
                    <div>
                        The web development for the Chinese restaurant website encompasses
                        a meticulous blend of frontend and backend technologies to create a seamless and engaging online experience. Here's a summary:
                    </div>
                    <div>
                        Utilizing a modern tech stack like the MERN (MongoDB, Express.js, React.js, Node.js) stack, the website is built to be robust, 
                        scalable, and efficient. The frontend design employs React.js to create interactive user interfaces, ensuring responsiveness across 
                        various devices and screen sizes.
                    </div>
                    <div>
                        Backend development, powered by Node.js and Express.js, establishes a resilient infrastructure to handle data management, user authentication, 
                        and RESTful API endpoints. Integration with MongoDB as the database ensures flexibility and scalability in storing and retrieving restaurant data, 
                        menu items, reservations, and user information.
                    </div>
                    <div>
                        A user-friendly reservation system and contact form enhance customer interaction and streamline communication between patrons and the restaurant. 
                        Seamless integration with third-party services for payment processing, booking management, and analytics further enriches the website's functionality and user experience.
                    </div>
                    <div>
                        Thorough testing protocols are implemented to identify and rectify any bugs or performance issues, ensuring the website operates smoothly across different browsers and platforms. 
                        Continuous monitoring and optimization strategies guarantee optimal performance and security, safeguarding against potential vulnerabilities and downtime.
                    </div>
                </div>
            </div>   
        </section>
        <Footer/>
    </div>
  )
}

export default LanternLightKitchen