import henryImage from "../../assets/images/desktop/home/henryOnyejelem.jpg"
import github from "../../assets/images/icons/githubIcon.png"
import instagram from "../../assets/images/icons/instagramIcon.png"
import behance from "../../assets/images/icons/behanceIcon.png"
import linkedIn from "../../assets/images/icons/linkedInIcon.png"
import linkIcon from "../../assets/images/icons/link1.svg"
import SlideUp from "../../components/ui/slideUp"
import SlideDown from "../../components/ui/slideDown"
import SlideImage from "../../components/ui/slideImage"
import TextSlide from "../../components/ui/textSlide"
import xdIcon from "../../assets/images/icons/adobexdIcon.png"
import figmaIcon from "../../assets/images/icons/figmaIcon.png"
import illustratorIcon from "../../assets/images/icons/illustratorIcon.png"
import reactIcon from "../../assets/images/icons/reactIcon.png"
import htmlIcon from "../../assets/images/icons/htmlcon.png"
import cssIcon from "../../assets/images/icons/cssIcon.png"
import nodeIcon from "../../assets/images/icons/nodeIcon.png"
import javascriptIcon from "../../assets/images/icons/javascriptIcon.png"
import sqlIcon from "../../assets/images/icons/sqlIcon.png"
import mongoDBIcon from "../../assets/images/icons/mongoDBIcon.png"
import expressIcon from "../../assets/images/icons/expressIcon.png"
import skills from "../../data/skills.js"
import coursework from "../../data/coursework.js"
import ProjectGallery from "../../components/ui/projectGallery.js"
import Logo from "../../components/ui/logo.js"
import "./index.css"
import { Link } from "react-router-dom"


function Home() {
  return (
      <div className="overflow-hidden">
        <div className="home pt-[10vh] pb-[5vh] w-[100vw] overflow-clip">
          <section className='personal-information mx-[11vw] mt-[5vh] sm:mx-[3vw] min-h-[85vh]'>
              <div className="flex justify-between items-end lg:flex-col-reverse lg:items-start gap-8 min-2xl:gap-[200px]">
                <div className="flex flex-col sm:gap-2 gap-5 lg:w-[100%]">
                  <div className="min-3xl:text-[100px] text-[55px]  md:text-[50px] sm:text-[45px] xs:text-[35px] md:leading-[55px] leading-[70px] sm:leading-[50px]">
                    <SlideUp delay={0.1} duration={1.5}>Hello there! I'm Henry.<br/></SlideUp> 
                    <SlideUp delay={0.25} duration={1.5}><span className="font-bold">Software Developer</span><br/></SlideUp>
                    <SlideUp delay={0.4} duration={1.5}>and <span className="font-bold">UI/UX Designer</span>.</SlideUp>
                  </div>
                  <div className="min-3xl:text-3xl text-xl leading-[33px] sm:text-sm xs:text-[15px] sm:leading-[50px] sm:flex sm:flex-col sm:gap-2">
                    <SlideUp delay={0.7} duration={1.35}>I am a Computer Science graduate deeply passionate</SlideUp>
                    <SlideUp delay={0.9} duration={1.2}>about crafting visually engaging software applications.</SlideUp>
                  </div>
                  <div className="flex justify-between items-center">
                    <div className="flex gap-4 sm:gap-2">
                      <SlideDown delay={1.1} duration={0.4}><a href="https://github.com/henryonyejelem" target="_blank" rel="noopener noreferrer">
                        <img src = {github} alt="" className="h-[30px] sm:h-[25px] min-3xl:h-[40px]"/>
                      </a></SlideDown>
                      <SlideDown delay={1.2} duration={0.4}><a href="https://www.behance.net/henryonyejelem" target="_blank" rel="noopener noreferrer">
                        <img src = {behance} alt="" className="h-[30px] sm:h-[25px] min-3xl:h-[40px]"/>
                      </a></SlideDown>
                      <SlideDown delay={1.3} duration={0.4}><a href="https://www.linkedin.com/in/henryonyejelem/" target="_blank" rel="noopener noreferrer">
                        <img src = {linkedIn} alt="" className="h-[30px] sm:h-[25px] min-3xl:h-[40px]"/>
                      </a></SlideDown>
                      <SlideDown delay={1.4} duration={0.4}><a href="https://www.instagram.com/henryonyejelem/" target="_blank" rel="noopener noreferrer">
                        <img src = {instagram} alt="" className="h-[30px] sm:h-[25px] min-3xl:h-[40px]"/>
                      </a></SlideDown>
                    </div>
                    <div className="flex items-center gap-0 text-xs min-3xl:text-base">
                      <div className="lg:hidden opacity-45 font-semibold flex gap-1"><TextSlide word="about" delay={1.6} duration={0.4}/> <TextSlide word="me" delay={2.0} duration={0.4}/></div>
                      <SlideDown delay={2.2} duration={0.8}><img src ={linkIcon} alt="" className="h-[2em]"/></SlideDown>
                    </div>
                  </div>             
                </div>
                <div className=''>
                  <SlideImage delay={0} duration={1.8}><img src={henryImage} alt="Henry" className="object-cover min-2xl:object-fill"/></SlideImage>
                </div>            
              </div>
          </section>
          <section className="skills mx-[11vw] lg:mt-8 sm:mt-3 sm:mx-[3vw]">
            <div className="text-4xl font-semibold lg:text-[50px] sm:text-[45px] xs:text-[35px]">
              Skills
            </div>
            <p className="text-base my-5 sm:my-1 sm:text-sm xs:text-[14px] sm:leading-[25px]">
              I specialize in crafting dynamic and interactive web experiences that captivate users and elevate online engagement. With an eye for design aesthetics,
              I bring creativity and precision to every project, ensuring visually stunning and user-friendly interfaces.
            </p>
            <div className="flex justify-between font text-base sm:text-xs sm:my-5 md:flex-col">
              <div className="min-md:w-[33%]">
                <p className="font-bold">User Experience</p>
                <div className="flex gap-10 xs:gap-6 my-5">
                  <img src={xdIcon} alt="" className="h-[50px]"/>
                  <img src={figmaIcon} alt="" className="h-[50px]"/>
                  <img src={illustratorIcon} alt="" className="h-[50px]"/>
                </div>
              </div>
              <div className="min-md:w-[33%]">
                <p className="font-bold min-md:text-center">Frontend</p>
                <div className="flex gap-10 xs:gap-6 my-5 flex-wrap min-md:items-center min-md:justify-center">
                  <img src={reactIcon} alt="" className="h-[50px]"/>
                  <img src={htmlIcon} alt="" className="h-[50px]"/>
                  <img src={cssIcon} alt="" className="h-[50px]"/>
                  <img src={javascriptIcon} alt="" className="h-[50px]"/>
                  <img src={nodeIcon} alt="" className="h-[50px]"/>
                </div>
              </div>
              <div className="min-md:w-[33%]">
                <p className="font-bold min-md:text-end">Backend</p>
                <div className="flex gap-10 xs:gap-6 my-5 min-md:flex-wrap min-md:justify-end">
                  <img src={sqlIcon} alt="" className="h-[35px]"/>
                  <img src={mongoDBIcon} alt="" className="h-[35px] md:h-[30px]"/>
                  <img src={expressIcon} alt="" className="h-[35px]"/>
                </div>            
              </div>
            </div>        
            
            <div className="flex items-start gap-3 text-base xs:text-sm my-5">
              <div className="w-[200px]">Other Skills: </div>
              <div className="flex flex-wrap gap-3">
                {skills.map((skill) => (
                  <div className="text-white rounded-full bg-[#787878] font-light px-4 text-sm xs:text-[14px]">{skill}</div>
                ))}            
              </div>
            </div>
          
          </section>
          <section className="academics mx-[11vw] mt-12 sm:mx-[3vw]">
            <div className="flex min-md:justify-between min-lg:items-center lg:flex-col">
              <div className="text-4xl font-semibold lg:text-[50px] sm:text-[45px] xs:text-[35px]">
                Academics
              </div>      
              <div className="flex md:text-xs font-bold min-sm:gap-4 sm:flex-col">
                <div><span className="text-7xl lg:text-6xl sm:text-[60px]">450</span>hours of relevant coursework</div>
                <div><span className="text-7xl lg:text-6xl sm:text-[70px]">3.9</span>/4.0 gpa</div>
              </div>    
            </div>
            <div className="flex items-start gap-3 text-base my-5 xs:text-sm">
                <div className="w-[280px]">Relevant Coursework: </div>
                <div className="flex flex-wrap gap-3">
                  {coursework.map((course) => (
                    <div className="text-white rounded-full bg-[#787878] font-light px-4 text-sm xs:text-[14px]">{course}</div>
                  ))}            
                </div>
              </div>
            
          </section>
          <section className="project mt-[13vh]">
            <div className="old-soul h-[90vh]">
            </div>
            <div className="bg-black px-[5vw] text-white font-semibold pb-[60px]"> 
              <div className="text-5xl text-right lg:text-[50px] sm:text-[45px] xs:text-[35px]">
                Portfolio
              </div>
              <div className="flex gap-10 text-xl md:text-xs font-bold min-sm:gap-3 sm:flex-col sm:gap-0">
                <div className=""><span className="text-7xl lg:text-6xl sm:text-[60px]">600</span>hours of project work</div>
                <div className=""><span className="text-7xl lg:text-6xl sm:text-[60px]">30+</span>projects</div>
              </div>
              <div className="text-[40px] mt-2 lg:text-[40px] sm:text-[30px] xs:text-[25px]">
                I combine creativity and practicality to make <br className="lg:hidden"/>
                my work unique and effective.
              </div>
            </div>
            <ProjectGallery/>      
          </section>
          <section className="footer mt-[10vh] mx-[5vw] md:mt-[5vh]">
            <div className="font-semibold text-5xl min-md:leading-[59px] text-right md:text-3xl ">
              <div>Have an idea? Let's</div>
              <div>build and scale it</div>
            </div>
            <div className="text-right text-base mt-[30px] md:text-xs">
              <div>Feel free to reach out if you want to collaborate with</div>
              <div>us, or simply have a chat.</div>
            </div>
            <div className="flex justify-end items-center text-base mt-[30px] md:mt-[15px]">
              <div><Link to="/contact"><img src={linkIcon} alt="" className="md:h-[70px]"/></Link></div>
              <div className="md:text-xs">Contact me!</div>
            </div>
            <div className="flex items-end justify-between">
              <div>
                <Logo/>
                <div className="mt-[10px] md:text-[10px]">© Henry Onyejelem 2024.All rights reserved</div>
              </div>
              <div>
                <div className="flex gap-4 sm:gap-2">
                  <a href="https://github.com/henryonyejelem" target="_blank" rel="noopener noreferrer"><img src = {github} alt="" className="h-[40px] sm:h-[25px]"/></a>
                  <a href="https://www.behance.net/henryonyejelem" target="_blank" rel="noopener noreferrer"><img src = {behance} alt="" className="h-[40px] sm:h-[25px]"/></a>
                  <a href="https://www.linkedin.com/in/henryonyejelem/" target="_blank" rel="noopener noreferrer"><img src = {linkedIn} alt="" className="h-[40px] sm:h-[25px]"/></a>
                  <a href="https://www.instagram.com/henryonyejelem/" target="_blank" rel="noopener noreferrer"><img src = {instagram} alt="" className="h-[40px] sm:h-[25px]"/></a>
                </div>
              </div>
            </div>
          </section>  
        </div>
      </div>
  )
}

export default Home;