import coding from "../../assets/images/icons/codingIcon.png"
import react from "../../assets/images/icons/reactBWIcon.png"
import illustrator from "../../assets/images/icons/adobeIllustratorIcon.png"
import xd from "../../assets/images/icons/adobexdBWIcon.png"
import framer from "../../assets/images/icons/framerMotionIcon.png"

function SlidingLogo() {
  return (
    <div className="relative overflow-hidden h-[1em] w-[1em]">
       <img src={coding} alt="" className="absolute animate-slide"/> 
       <img src={react} alt="" className="absolute animate-slide [animation-delay:0.83s] -translate-y-full"/> 
       <img src={xd} alt="" className="absolute animate-slide [animation-delay:1.67s] -translate-y-full"/> 
       <img src={illustrator} alt="" className="absolute animate-slide [animation-delay:2.5s] -translate-y-full"/> 
       <img src={framer} alt="" className="absolute animate-slide [animation-delay:3.33s] -translate-y-full"/>         
    </div>
  )
}
export default SlidingLogo