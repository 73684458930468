import "./index.css"
import github from "../../assets/images/icons/github.svg"
import behance from "../../assets/images/icons/behance.svg"
import link from "../../assets/images/icons/link1.svg"
import xd from "../../assets/images/icons/adobexdIcon.png"
import illustrator from "../../assets/images/icons/illustratorIcon.png"
import react from "../../assets/images/icons/reactIcon.png"
import express from "../../assets/images/icons/expressIcon.png"
import mongo from "../../assets/images/icons/mongoDBIcon.png"
import framer from "../../assets/images/icons/framerIcon.png"
import photoshop from "../../assets/images/icons/photoshopIcon.png"
import maya from "../../assets/images/icons/mayaIcon.png"
import node from "../../assets/images/icons/nodeIcon.png"
import webDesign from "../../assets/images/desktop/project/oldSoulWebDesign.jpg"
import mockups from "../../assets/images/desktop/project/oldSoulMockups.jpg"
import branding from "../../assets/images/desktop/project/oldSoulBranding.jpg"
import Footer from "../../components/ui/footer"
import SlideUp from "../../components/ui/slideUp"

function OldSoul() {
    const height = window.innerHeight;
  return (
    <div className="mb-[3vw] w-[100vw]">
        <section className="old-soul-project flex" style={{height:height}}>
            <div className="flex self-end text-white p-[5vw] justify-between w-[100%] min-xs:items-center xs:flex-col xs:gap-3">
            <div className="min-2xl:text-[80px] text-[55px] font-semibold sm:text-[50px]"><SlideUp delay={0.1} duration={0.8}>Old Soul</SlideUp></div>
                <div className="flex gap-5 text-xl min-2xl:text-3xl sm:text-sm  sm:gap-3">
                    <div><SlideUp delay={0.3} duration={0.6}>2024</SlideUp></div>
                    <div><SlideUp delay={0.45} duration={0.6}>Fullstack Development</SlideUp></div>
                    <div><SlideUp delay={0.6} duration={0.6}>UI/UX</SlideUp></div>
                </div>
            </div>
        </section>
        <section className="information px-[5vw] pt-[5vw] text-xl">
            <div className="min-md:w-[650px] min-2xl:w-[1000px] min-2xl:text-[80px] min-2xl:leading-[95px] add-stroke text-[56px] leading-[77px] sm:text-[45px] sm:leading-[60px] xs:text-[40px] xs:leading-[50px]">
                Welcome to Old Soul, where nostalgia meets style in every stitch. 
                Old Soul brings a curated collection of vintage-inspired garments to the modern wardrobe.
            </div>
            <section className="description flex gap-[4rem] mt-[60px] md:flex-col">
                <div className="min-md:w-[50%]">
                    In this personal portfolio project, I endeavor to seamlessly fuse the evocative essence of vintage 
                    fashion with the cutting-edge principles of modern web design. Drawing inspiration from the graceful eras of bygone styles.
                </div>
                <div className="min-md:w-[50%]">
                    The project is a homage to the rich tapestry of fashion history, from the flapper dresses of the 1920s 
                    to the mod styles of the 1960s and beyond. Through Old Soul, we seek to revive the elegance of the past and infuse it with a contemporary twist.
                </div>
            </section>
            <section className="duration&links flex gap-[4rem] mt-[70px] md:flex-col md:gap-4">
                <div className="min-md:w-[50%]">
                    <div className="text-sm text-[#707070]">Duration</div>
                    <div className="text-[130px] font-bold">10<span className="text-xl">weeks</span></div>                    
                </div>
                <div className="min-md:w-[50%]">                    
                    <div className="text-sm text-[#707070]">Links</div>
                    <div className="mt-[50px] flex gap-3 sm:gap-4">
                        <div className="flex items-end gap-2">
                            <img src={github} className="h-[85px] sm:h-[55px]" alt=""/>
                            <div className="flex items-center">
                                <div>Github</div>
                                <a href="https://github.com/henryonyejelem/old_soul" target="_blank" rel="noopener noreferrer"><img src={link} alt="" className="h-[30px]"/></a>
                            </div>                            
                        </div>
                        <div className="flex items-end gap-2">
                            <img src={behance} className="h-[85px] sm:h-[55px]" alt=""/>
                            <div className="flex items-center">
                                <div>Behance</div>
                                <a href="https://www.behance.net/gallery/192187463/Old-Soul" target="_blank" rel="noopener noreferrer"><img src={link} alt="" className="h-[30px]"/></a>
                            </div>                            
                        </div>                    
                    </div>  
                </div>
            </section>
            <section className="timeline mt-[60px]">
                <div className="text-sm text-[#707070]">Timeline</div>
                <div className="grid grid-cols-2 md:grid-cols-1 gap-[4rem] text-base mt-5">
                    <div className="">
                        <div className="font-semibold">1 week</div>
                        <div className="mt-3">Defining the project scope and objectives. Identifying the 
                            target audience and establish the branding for the website. 
                            Conduct research on similar websites to inform the design and functionality. 
                            Set initial project milestones and timelines.
                        </div>
                    </div>
                    <div className="">
                        <div className="font-semibold">2 week</div>
                        <div className="mt-3">
                            Designing a distinctive logo for the website. 
                            Engaging in brainstorming and conceptualization, iterating through design ideas with feedback rounds. 
                            Finalizing and approving the logo design that aligns with the restaurant's identity.
                        </div>
                    </div>
                    <div className="">
                        <div className="font-semibold">3-4 week</div>
                        <div className="mt-3">
                            Develop the information architecture of the website. Create wireframes 
                            to outline the layout. Design UI/UX elements for a seamless user experience.
                            Review and refine the design with input from stakeholders to ensure it aligns 
                            with the restaurant's vision.
                        </div>
                    </div>
                    <div className="">
                        <div className="font-semibold">5-7 week</div>
                        <div className="mt-3">
                            Translate the design into HTML and CSS for the website's structure. Implement 
                            responsive design for compatibility across various devices. Integrate UI/UX elements and 
                            initiate feedback loops for further refinement.
                        </div>
                    </div>
                    <div className="">
                        <div className="font-semibold">8-9 week</div>
                        <div className="mt-3">
                            Choose and set up a Content Management System (CMS).
                            the database structure and implement server-side functionality. 
                            Integrate the backend with frontend components to create a cohesive and functional website
                        </div>
                    </div>
                    <div className="">
                        <div className="font-semibold">10 week</div>
                        <div className="mt-3">
                            Conduct thorough user acceptance testing (UAT) to ensure the website meets expectations. 
                            Perform cross-browser compatibility testing and check mobile responsiveness. 
                            Identify and address any bugs or issues that arise during testing.
                        </div>
                    </div>
                    
                    
                    
                </div>

            </section>
            <section className="tools min-md:w-[45%] mt-[60px]">
                <div className="text-sm text-[#707070]">Tools</div>    
                <div className="flex flex-wrap mt-6 gap-9">
                    <img src={xd} alt="" className="h-[50px]"/>
                    <img src={illustrator} alt="" className="h-[50px]"/>
                    <img src={photoshop} alt="" className="h-[50px]"/>
                    <img src={react} alt="" className="h-[50px]"/>
                    <img src={framer} alt="" className="h-[50px]"/>
                    <img src={node} alt="" className="h-[50px]"/>
                    <img src={express} alt="" className="h-[50px]"/>
                    <img src={mongo} alt=""className="h-[50px]"/>
                    <img src={maya} alt="" className="h-[50px]"/>
                </div>            
            </section>
        </section>
        <section className="web-design pt-[12vh]">
            <div className="px-[5vw]">
                <div className=" text-[45px] add-stroke">Web Design</div>
                <div className="text-base flex gap-[150px] mt-10">
                    <div className="flex flex-col gap-6">
                        <div>
                            The entire website greets visitors with a seamless fusion of curated visuals and 
                            intuitive navigation, embodying Old Soul's commitment to refined design. 
                            Throughout its pages, captivating visuals and intuitive menus guide users 
                            through curated collections and seasonal highlights, resonating with the 
                            discerning tastes of its clientele.
                        </div>
                        <div>
                            Central to Old Soul's design ethos is a dedication to seamless functionality and 
                            elevated aesthetics across all devices. Whether accessed on desktop or mobile, the 
                            website ensures a consistent experience, offering effortless browsing of curated 
                            collections and personalized styling tips to inspire visitors wherever they are.
                        </div>
                    </div>
                    <img src={webDesign} alt="" className="w-[40vw] md:hidden object-cover min-2xl:hidden"/>
                </div>                
            </div>
        </section>
        <section className="mockups pt-[12vh]">
            <img src={mockups} alt="" className="w-[100vw]"/>
        </section>
        <section className="web development pt-[12vh] px-[5vw]">
            <div className=" text-[45px] add-stroke">Web Development</div>
            <div className="text-base gap-[150px] mt-10">
                <div className="flex flex-col gap-6">
                    <div>
                        The website is built on the MERN stack (MongoDB, Express.js, React.js, Node.js), a
                        powerful combination for modern web development. MongoDB serves as the database,
                        while Express.js manages backend operations. React.js is utilized to create dynamic 
                        user interfaces, and Node.js powers the backend server. Together, these technologies 
                        enable efficient communication between frontend and backend components, facilitating 
                        rapid development and scalability of the application.
                    </div>
                    <div>
                        With MongoDB's robust data storage, Express.js's efficient handling of backend operations, 
                        React.js's creation of dynamic user interfaces, and Node.js's powerful backend server capabilities, 
                        the MERN stack ensures streamlined development and exceptional user experiences. The synergy between 
                        these technologies allows for seamless communication and scalability, empowering developers to create
                        feature-rich web applications that meet the demands of today's dynamic digital landscape.
                    </div>
                </div>
            </div>   
        </section>
        <section className="branding pt-[12vh] px-[5vw]">
            <div className=" text-[45px] add-stroke">Branding</div>
            <div className="text-base gap-[150px] mt-10">
                <div className="flex flex-col gap-6">
                    <div>
                        To craft images for branding purposes, I employed a combination of Maya and Photoshop, leveraging 
                        their respective strengths in 3D modeling and image editing. In Maya, I sculpted and textured 
                        intricate 3D models, meticulously shaping each element to align with the brand's visual identity and 
                        message. Through Maya's powerful tools, I brought concepts to life, ensuring that every detail contributed
                        to the overall aesthetic and storytelling.
                    </div>
                    <div>
                        Once the 3D models were refined to perfection, I exported them into Photoshop to enhance and refine their visual 
                        impact. In Photoshop, I applied lighting effects, color adjustments, and intricate details to elevate the images 
                        to their fullest potential. Through layers, masks, and blending modes, I fine-tuned each element, ensuring cohesiveness 
                        and harmony within the visual narrative.
                    </div>
                </div>
            </div>   
        </section>
        <section className="branding-images pt-[12vh]">
            <img src={branding} alt="" className="w-[100vw]"/>
        </section>
        <Footer/>
    </div>
  )
}

export default OldSoul