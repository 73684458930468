import SlidingLogo from "./slidingLogo"
import LoadingBar from "./loadingBar"

function LoadingPage() {
    const height = window.innerHeight;  
  return (
    <section className={`bg-black relative]`} style = {{height : height}}>      
      <div className='text-white flex flex-col items-center absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-[40px] md:text-[30px]'>
        <div className="flex flex-row-reverse gap-3 items-center">
          <div className='font-bold'>HO</div>  
          <SlidingLogo/> 
        </div>   
        <LoadingBar/> 
      </div>
    </section>
  )
}

export default LoadingPage