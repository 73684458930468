import "./index.css"
import github from "../../assets/images/icons/github.svg"
import behance from "../../assets/images/icons/behance.svg"
import link from "../../assets/images/icons/link1.svg"
import xd from "../../assets/images/icons/adobexdIcon.png"
import illustrator from "../../assets/images/icons/illustratorIcon.png"
import react from "../../assets/images/icons/reactIcon.png"
import framer from "../../assets/images/icons/framerIcon.png"
import photoshop from "../../assets/images/icons/photoshopIcon.png"
import node from "../../assets/images/icons/nodeIcon.png"
import webDesign from "../../assets/images/desktop/project/rosarioWebDesign.png"
import mockups from "../../assets/images/desktop/project/rosarioMockup.jpg"
import Footer from "../../components/ui/footer"
import SlideUp from "../../components/ui/slideUp"

function Rosario() {
    const height = window.innerHeight;
  return (
    <div className="mb-[3vw] w-[100vw]">
        <section className="rosario-project flex" style={{height:height}}>
            <div className="flex self-end text-white p-[5vw] justify-between items-start w-[100%] min-xs:items-center xs:flex-col xs:gap-3">
                <div className="min-2xl:text-[80px] text-[55px] font-semibold sm:text-[50px] sm:hidden">
                    <SlideUp delay={0.1} duration={0.8}>The Little Boy From</SlideUp>
                    <SlideUp delay={0.1} duration={0.8}>Rosario</SlideUp>
                </div>
                <div className="min-2xl:text-[80px] text-[55px] font-semibold sm:text-[50px] min-sm:hidden">
                    <SlideUp delay={0.1} duration={0.8}>The Little Boy</SlideUp>
                    <SlideUp delay={0.1} duration={0.8}>From Rosario</SlideUp>
                </div>
                < div className="flex gap-5 items-stretch text-xl min-2xl:text-3xl sm:text-sm  sm:gap-3">
                    <div><SlideUp delay={0.3} duration={0.6}>2023</SlideUp></div>
                    <div><SlideUp delay={0.45} duration={0.6}>Frontend Development</SlideUp></div>
                    <div><SlideUp delay={0.6} duration={0.6}>UI/UX</SlideUp></div>
                </div>
            </div>
        </section>
        <section className="information px-[5vw] pt-[5vw] text-xl">
            <div className="min-md:w-[650px] min-2xl:w-[1000px] min-2xl:text-[80px] min-2xl:leading-[95px] add-stroke text-[56px] leading-[77px] sm:text-[45px] sm:leading-[60px] xs:text-[40px] xs:leading-[50px]">
                "Lionel Messi has conquered his final peak"
            </div>
            <section className="description flex gap-[4rem] mt-[60px] flex-col min-md:items-end relative">
                <div className="text-sm text-[#707070] md:hidden self-start absolute top-0 left-0">(about)</div>
                <div className="min-md:w-[50%]">
                    This is a focused web documentary capturing Lionel Messi's pursuit of victory 
                    in the 2022 World Cup. The site immerses users in Messi's pivotal role in his  World Cup journey. 
                </div>
                <div className="min-md:w-[50%]">
                    With a streamlined focus on the tournament, the site offers fans an engaging narrative, culminating 
                    in Messi's historic achievement as he leads Argentina to World Cup glory in 2022.
                </div>
            </section>
            <section className="duration&links flex gap-[4rem] mt-[70px] md:flex-col md:gap-4">
                <div className="min-md:w-[50%]">
                    <div className="text-sm text-[#707070]">Duration</div>
                    <div className="text-[130px] font-bold">6<span className="text-xl">weeks</span></div>                    
                </div>
                <div className="min-md:w-[50%]">                    
                    <div className="text-sm text-[#707070]">Links</div>
                    <div className="mt-[50px] flex gap-3 sm:gap-4">
                        <div className="flex items-end gap-2">
                            <img src={github} className="h-[85px] sm:h-[55px]" alt=""/>
                            <div className="flex items-center">
                                <div>Github</div>
                                <a href="https://github.com/henryonyejelem/the-little-boy-from-rosario" target="_blank" rel="noopener noreferrer"><img src={link} alt="" className="h-[30px]"/></a>
                            </div>                            
                        </div>
                        <div className="flex items-end gap-2">
                            <img src={behance} className="h-[85px] sm:h-[55px]" alt=""/>
                            <div className="flex items-center">
                                <div>Behance</div>
                                <a href="https://www.behance.net/gallery/185153285/The-Little-Boy-from-Rosario" target="_blank" rel="noopener noreferrer"><img src={link} alt="" className="h-[30px]"/></a>
                            </div>                            
                        </div>                    
                    </div>  
                </div>
            </section>
            <section className="timeline mt-[60px]">
                <div className="text-sm text-[#707070]">Timeline</div>
                <div className="grid grid-cols-2 md:grid-cols-1 gap-[4rem] text-base mt-5">
                    <div className="">
                        <div className="font-semibold">1 week</div>
                        <div className="mt-3">
                            During the first week, the project focuses on planning and conceptualization.
                            This includes defining the theme, researching content, and sketching the structure 
                            of the documentary. Wireframing and prototyping help visualize the user interface and navigation flow.
                        </div>
                    </div>
                    <div className="">
                        <div className="font-semibold">2 week</div>
                        <div className="mt-3">
                            Week two involves setting up the project infrastructure. This includes creating a React project, 
                            installing dependencies like React Router, and implementing the initial design using Framer Motion for animations and transitions.
                        </div>
                    </div>
                    <div className="">
                        <div className="font-semibold">3 week</div>
                        <div className="mt-3">
                            In the third week, development and integration take center stage. Multimedia content such as videos and images are integrated into the 
                            application, and interactive features are implemented. Attention is paid to responsiveness and ensuring a seamless user experience.
                        </div>
                    </div>
                    <div className="">
                        <div className="font-semibold">4 week</div>
                        <div className="mt-3">
                            Week four is dedicated to refinement and testing. Usability testing is conducted to gather feedback, bugs are identified and fixed, 
                            and the user interface undergoes polish for consistency and visual appeal.
                        </div>
                    </div>
                    <div className="">
                        <div className="font-semibold">5 week</div>
                        <div className="mt-3">
                            The fifth week focuses on finalizing content integration and conducting thorough cross-browser testing. The application is prepared for 
                            deployment, and hosting options are explored. The deployment process is completed, ensuring that the web documentary is accessible to users
                        </div>
                    </div>
                    <div className="">
                        <div className="font-semibold">6 week</div>
                        <div className="mt-3">
                            In the final week, post-launch activities commence. User engagement is monitored through analytics tools, feedback is collected,
                            and any post-launch issues are addressed promptly. Plans for future updates and expansions are formulated based on user response 
                            and project goals.
                        </div>
                    </div>
                </div>
            </section>
            <section className="tools min-md:w-[45%] mt-[60px]">
                <div className="text-sm text-[#707070]">Tools</div>    
                <div className="flex flex-wrap mt-6 gap-9">
                    <img src={xd} alt="" className="h-[50px]"/>
                    <img src={illustrator} alt="" className="h-[50px]"/>
                    <img src={photoshop} alt="" className="h-[50px]"/>
                    <img src={react} alt="" className="h-[50px]"/>
                    <img src={framer} alt="" className="h-[50px]"/>
                    <img src={node} alt="" className="h-[50px]"/>
                </div>            
            </section>
        </section>
        <section className="web-design pt-[12vh]">
            <div className="px-[5vw]">
                <div className=" text-[45px] add-stroke">Web Design</div>
                <div className="text-base flex flex-row-reverse gap-[35px] mt-10">
                    <div className="flex gap-6">
                        <div>
                            The website design for the web documentary project prioritizes immersive storytelling 
                            through a modern and clean aesthetic. Its layout is intuitive, with distinct sections 
                            for chapters, interviews, and supplementary materials. Multimedia elements are seamlessly 
                            integrated to enhance storytelling, while responsive design ensures optimal viewing across 
                            devices. Interactive elements, including smooth animations powered by Framer Motion, 
                            encourage user engagement. Accessibility and usability are paramount, adhering to web 
                            standards for inclusivity. Consistent branding reinforces the project's identity, 
                            inviting users on a journey of exploration and discovery.
                        </div>
                    </div>
                    <img src={webDesign} alt="" className="w-[40vw] md:hidden object-cover min-2xl:hidden"/>
                </div>                
            </div>
        </section>
        <section className="mockups pt-[12vh]">
            <img src={mockups} alt="" className="w-[100vw]"/>
        </section>
        <section className="web development pt-[12vh] px-[5vw]">
            <div className=" text-[45px] add-stroke">Web Development</div>
            <div className="text-base gap-[150px] mt-10">
                <div className="flex flex-col gap-6">
                    <div>
                        The web development process for the documentary project employs React.js 
                        and Framer Motion to create a dynamic and responsive platform. It follows 
                        a component-based architecture for modularity and reuse. Data management 
                        is handled using state management libraries like React Context API or Redux. 
                        React Router enables smooth navigation, while responsive design principles 
                        ensure compatibility across devices. Accessibility features and performance 
                        optimization techniques are integrated, and thorough testing is conducted for 
                        compatibility and bug resolution. Continuous integration and deployment pipelines 
                        streamline the release cycle, ensuring a seamless user experience throughout.
                    </div>
                </div>
            </div>   
        </section>
        <Footer/>
    </div>
  )
}

export default Rosario